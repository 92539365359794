<template>
  <router-view v-if="isAuthRoute"/>
  <v-app v-else-if="isLogged">
  <navigation/>
    <v-main v-bind:style="{ backgroundColor}">
        <!-- // ToDo move this to component -->
        <v-container v-if="(!user.email || !user.phone) && !['TV.advance-payment', 'TV'].includes($route.name)" class="py-0">
          <v-row no-gutters>
            <v-col>
              <v-alert
                class="mt-3"
                border="left"
                colored-border
                type="info"
                dismissible
                style="border: thin solid rgba(0, 0, 0, 0.12); margin-bottom: -10px;"
              >
                {{ $t('You need to add') }}
                <strong v-if="!user.email">email</strong>
                <template v-if="!user.email">
                  &
                </template>
                <strong v-if="!user.phone">{{ $t('Phone number') }}</strong>
                {{ $t('to your account') }}.
                {{ $t('Please visit') }}
                <router-link :to="{name:'user-settings.account'}" class="text-decoration-none font-weight-bold">
                  {{ $t('Account Settings') }}
                </router-link>
                {{ $t('and fill the form') }}.
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
        <router-view/>
    </v-main>
    <v-snackbar
      v-for="(message) in messages"
      :key="message.id"
      v-model="message.model"
      right
      dark
      top
      :timeout="2000">
      {{ message.text }}</v-snackbar>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  mounted() {
    // Bookaweb logo that displays in console
    const stringLogo = `
        ⬜⬜⬜⬜⬜
      ⬜           ⬜
      ⬜             ⬜
      ⬜     ⬜⬜    ⬜
      ⬜   ⬜    ⬜ ⬜
      ⬜   ⬜      ⬜
      ⬜   ⬜       ⬜
      ⬜    ⬜    ⬜  ⬜
      ⬜      ⬜⬜     ⬜
      ⬜                ⬜
      ⬜                ⬜
      ⬜               ⬜
        ⬜⬜⬜⬜⬜⬜⬜

      ${this.$t('Welcome to Bookaweb')}!
    `;
    console.log(stringLogo);
  },
  computed: {
    ...mapGetters([
      'isLogged', 'user', 'backgroundColor',
    ]),
    isAuthRoute() {
      return ['Login', 'Register', 'VerifyEmail', 'ResetPassword'].includes(this.$route.name);
    },
    messages: {
      get() {
        return this.$store.getters.messages;
      },
      set(newName) {
        return newName;
      },
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
  },

};
</script>
